import HTTPResponse from "./Entities/HTTPResponse";
import qs from 'qs';

export { HTTPServiceSingleton as default };

class HTTPService {
  /**
   * Saves current request data
   * to prevent duplicated request
   *
   * @private
   *
   * @type {Object<string, HTTPResponse>}
   */
  current_requests = {};

  /**
   * Makes a post request using form url encoded data
   * All requests with the same id will wait for the first request
   * to finish and return the same response.
   *
   * @public
   *
   * @param {String} endpoint   server endpoint
   * @param {Object} data       post data key => value
   * @param {String} request_id request id
   *
   * @returns {Promise<HTTPResponse>}
   */
  async post(endpoint, data = {}, request_id = null) {

    const params = {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      body: (data instanceof FormData) ? data : qs.stringify(data),
    };

    if (data instanceof FormData) {
      delete params.headers;
    }

    if (this.current_requests[request_id]) {
      return await this.awaitForRequest(request_id);
    }

    try {
      const entity = this.setCurrentRequest(request_id);
      const response = await fetch(endpoint, params);
      const results = await response.json();
      this.parseResponse(entity, results, response.status);
      return entity;
    } catch (error) {
      return this.handleError(error);
    } finally {
      this.unsetCurrentRequest(request_id);
    }
  }

  /**
   * Makes a get request to a remote url
   *
   * @public
   *
   * @param {String} endpoint   server endpoint
   *
   * @returns {Promise<HTTPResponse>}
   */
  async get(endpoint, payload = {}) {
    const entity = new HTTPResponse;

    const params = {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      // credentials: "include"
    };

    try {
      const url = this.generateUrlWithParams(endpoint, payload);
      const response = await fetch(`${url}`, params);
      const results = await response.json();

      this.parseResponse(entity, results, response.status);

      return entity;
    } catch (error) {
      return this.handleError();
    }
  }

  /**
   * Parses HTTP Response into the an HTTPResponse
   *
   * @private
   *
   * @param {HTTPResponse} entity  entity to parse the results into
   * @param {Object} results http raw results
   *
   * @returns {void}
   */
  parseResponse(entity, results, status_code) {
    if (typeof results.status === 'undefined') {
      throw Error('Server results are empty');
    }

    entity.success = status_code === 200 ? results.status : false;
    entity.results = results.data;
    entity.messages = results.message ?? null;
  }

  /**
   * Sets the current request
   *
   * @private
   * @param {string|null} request_id
   *
   * @returns {HTTPResponse}
   */
  setCurrentRequest(request_id) {
    if (!request_id) {
      return new HTTPResponse();
    }

    this.current_requests[request_id] = this.current_requests[request_id] || new HTTPResponse();
    return this.current_requests[request_id];
  }

  /**
   * Deletes the current request
   *
   * @private
   * @param {string|null} request_id
   * @returns {HTTPResponse}
   */
  unsetCurrentRequest(request_id) {
    delete this.current_requests[request_id];
  }

  /**
   * Waits for a request with the same id to be finished
   *
   * @private
   * @param {String|null} request_id request id
   * @param {Number}      timeout    wait timeout in ms
   *
   * @returns {Promise<HTTPResponse>}
   */
  async awaitForRequest(request_id, timeout = 100) {
    if (!this.current_requests[request_id]) {
      return;
    }

    return new Promise(resolve => {
      setTimeout(() => {
        resolve(this.awaitForRequest(request_id));
      }, timeout);
    });
  }

  /**
   * Generate a url with base in payload
   *
   * @private
   *
   * @param {String} endpoint
   * @param {Object} payload
   *
   * @returns {String}
   */
  generateUrlWithParams(endpoint, payload) {
    if (!payload || !Object.keys(payload).length) {
      return endpoint;
    }

    return (`${endpoint}?` + this.objectToQueryString(payload));
  }

  /**
   * Formatt objeto to queryString
   *
   * @private
   *
   * @param {Object} params
   *
   * @returns {String}
   */
  objectToQueryString(params = {}) {
    const esc = encodeURIComponent;
    return Object.keys(params)
      .map(key => esc(key) + '=' + esc(params[key]))
      .join('&');
  }

  /**
   * This function returns an HTTPResponse object with a success value of false and an error message.
   * @param {Object} error
   * @returns {Promise<HTTPResponse>}
   */
  handleError(error) {
    const entity = new HTTPResponse();
    entity.success = false;
    entity.messages = error?.message || 'http_client_error';
    return entity;
  }
}

const HTTPServiceSingleton = new HTTPService;