import IMask from 'imask';

export { InputMask as default };

class InputMask {
  /**
   * Aplica uma mascara em um elemento HTML
   *
   * @param {String} element_string ID or Class
   * @param {String|null} mask_format
   * @returns {Object.<IMask>}
   */
  applyMask(element_string, mask_format) {
    const element = document.querySelector(element_string);

    if (!element || !mask_format) {
      return;
    }

    const options = {
      mask: mask_format
    };

    return IMask(element, options);
  }
}