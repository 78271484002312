export { CookieSingleton as default };

class Cookie {

  /**
   * Duracao de cookie que sera mantida no site
   *
   * @type {Number}
   */
  days = 30;

  /**
   * Lista de termos para validar via
   * url do site
   *
   * @type {Array}
   */
  utm_mapping = [
    'utm_source', 'utm_medium',
    'utm_campaign', 'utm_term',
    'utm_content', 'fbclid', 'tech_midia',
    'gclid'
  ];

  /**
   * Converte valores com base na chaves de
   * query do site
   *
   * @type {Object}
   */
  special_utm_values = {
    'fbclid': 'fb-ads',
    'gclid': 'adwords'
  }

  /**
   * Algumas chaves query precisam de tratar os dados
   * diferentemente de outros sendo assim invoca funcoes
   * customizadas
   *
   * @type {Object}
   */
  utm_functions = {
    'utm_source': (value) => this.handleUtmSource(value),
    'fbclid': (value) => this.handleTechMidia('fbclid', value),
    'gclid': (value) => this.handleTechMidia('gclid', value),
  }

  /**
   * Inicia as configuracoes e carregamentos dos cookies
   *
   * @public
   * @returns {void}
   */
  initialize() {
    this.utm_mapping.forEach(parameter => this.handleUtmParameter(parameter));
    this.handleReferer();
  }

  /**
   * Trata os dados que sao originados do utm_mapping e
   * verifica os dados da url
   *
   * @private
   * @param {String} parameter
   * @returns {void}
   */
  handleUtmParameter(parameter) {
    let value = this.query(parameter);

    if (!value) {
      return;
    }

    if (this.utm_functions[parameter]) {
      this.utm_functions[parameter](value);
      return;
    }

    this.setCookie(parameter, value, this.days);
  }

  /**
   * Trata os dados das techmidias que sao especiais.
   *
   * @private
   * @param {String} parameter
   * @param {String} value
   * @returns {void}
   */
  handleTechMidia(parameter, value) {
    const special_value = this.special_utm_values[parameter] || null;

    if (!special_value) return;

    this.setCookie('tech-midia', special_value, this.days);
    this.setCookie(parameter, value, this.days);
  }

  /**
   * faz um tratamento especial para UTM_SOURCE
   * que pode conter diferentes valores
   *
   * @private
   * @returns {void}
   */
  handleUtmSource(value) {
    if (value.startsWith('google') || value.startsWith('adwords')) {
      this.setCookie('tech-midia', 'adwords', this.days);
      return;
    }

    this.setCookie('tech-midia', value, this.days);
  }

  /**
   * Faz o tratamento de referências de URL
   *
   * @private
   * @returns {void}
   */
  handleReferer() {
    if (this.get('tech-midia')) return;

    let domain = this.parseURL(document.referrer);
    let currentDomain = this.parseURL(document.location.host);

    if (!document.referrer || (domain && currentDomain && domain.host === currentDomain.host)) {
      this.setCookie('tech-midia', 'direto', this.days);
      return;
    }

    if (domain) {
      let refer;
      switch (domain.host) {
        case "google":
          refer = 'organico';
          break;
        case "facebook":
          refer = 'facebook';
          break;
        default:
          refer = 'referencial';
          break;
      }
      this.setCookie('tech-midia', refer, this.days);
      return;
    }

    this.setCookie('tech-midia', 'direto', this.days);
  }

  /**
   * Identifica se na url possui um parametro
   * e retorna seu valor
   *
   * @public
   * @param {String} name
   * @returns {String}
   */
  query(name) {
    var url = window.location.href;

    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  /**
   * Realiza um parse na URL pegando dados principais do site
   *
   * @public
   * @param {String} url
   * @returns {Object}
   */
  parseURL(url) {
    var parsed_url = {};

    if (url == null || url.length == 0)
      return parsed_url;

    var protocol_i = url.indexOf('://');
    if (protocol_i != -1) {
      parsed_url.protocol = url.substr(0, protocol_i);
      var remaining_url = url.substr(protocol_i + 3, url.length);
    } else {
      parsed_url.protocol = null;
      var remaining_url = url;
    }

    var domain_i = remaining_url.indexOf('/');
    domain_i = domain_i == -1 ? remaining_url.length - 1 : domain_i;
    parsed_url.domain = remaining_url.substr(0, domain_i);
    parsed_url.path = domain_i == -1 || domain_i + 1 == remaining_url.length ? null : remaining_url.substr(domain_i + 1, remaining_url.length);

    var domain_parts = parsed_url.domain.split('.');
    switch (domain_parts.length) {
      case 2:
        parsed_url.subdomain = null;
        parsed_url.host = domain_parts[0];
        parsed_url.tld = domain_parts[1];
        break;
      case 3:
        parsed_url.subdomain = domain_parts[0];
        parsed_url.host = domain_parts[1];
        parsed_url.tld = domain_parts[2];
        break;
      case 4:
        parsed_url.subdomain = domain_parts[0];
        parsed_url.host = domain_parts[1];
        parsed_url.tld = domain_parts[2] + '.' + domain_parts[3];
        break;
    }

    parsed_url.parent_domain = parsed_url.host + '.' + parsed_url.tld;

    return parsed_url;
  }

  /**
   * Adiciona um cookie no site
   *
   * @public
   * @param {String} name
   * @param {String} value
   * @param {Number} days
   * @returns {void}
   */
  setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  /**
   * Retorna um valor do cookie com base numa chave
   * fazendo um tratamento para retornar vazio caso
   * estiver null
   *
   * @public
   * @param {String} name
   * @returns {String}
   */
  get(name) {
    let value = this.handleGetCookie(name);

    if (!value || value === null || value === 'null') {
      return '';
    }

    return value;
  }

  /**
   * Recupera o valor do Cookie
   */
  handleGetCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
}

const CookieSingleton = new Cookie;