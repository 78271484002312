export { HTTPResponse as default };

/**
* HTTP Response Entity
*
* @author      Roni Sommerfeld <roni@4tech.mobi>
*/
class HTTPResponse {
  /**
   * Defines if response success
   *
   * @public
   *
   * @type {Boolean}
   */
  success;

  /**
   * Parsed results
   *
   * @public
   *
   * @type {*}
   */
  results;

  /**
   * Response messages
   *
   * @public
   *
   * @type {Object}
   */
  messages;
}