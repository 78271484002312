import AppController from '../../App/AppController';
import WhatsappCollection from '../Collections/WhatsappCollection';

export { WhatsappData as default };

class WhatsappData {
  /**
   * Identifica se o whatsapp ativa
   * @type {Number}
   */
  has_whatsapp = 0;

  /**
   * Aloca automaticamente o whatsapp para um user
   *
   * @type {Number}
   */
  auto_allocate_whatsapp = 0;

  /**
   * Lista que contém as configurações
   */
  whatsapp = new WhatsappCollection;

  /**
   * Mapea os campos que estao vindo do AppEntity
   *
   * @returns {void}
   */
  async mapFromAppEntity() {
    this.has_whatsapp = parseInt(AppController.entity.has_whatsapp);
    this.auto_allocate_whatsapp = parseInt(AppController.entity.auto_allocate_whatsapp);

    if (!this.has_whatsapp) {
      return;
    }

    await this.whatsapp.mapCollection(AppController.entity.whatsapp);
  }
}