'use strict';

import AppController from "./Modules/App/AppController";

function ready(callbackFunc) {
  if (document.readyState !== 'loading') {
    // Document is already ready, call the callback directly
    callbackFunc();
  } else if (document.addEventListener) {
    // All modern browsers to register DOMContentLoaded
    document.addEventListener('DOMContentLoaded', callbackFunc);
  } else {
    // Old IE browsers
    document.attachEvent('onreadystatechange', function () {
      if (document.readyState === 'complete') {
        callbackFunc();
      }
    });
  }
}

ready(function () {
  if (typeof gtag != 'undefined') {
    console.log("🤖: Found gtag");
  } else {
    console.log("🤖: Don't found gtag");
  }

  AppController.initialize();
});
