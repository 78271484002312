import LeadHTTP from "./HTTP/LeadHTTP";
import LeadHTTPMapping from "./HTTP/LeadHTTPMapping";
import LeadEntity from "./Entities/LeadEntity";
import Cookie from '@/Services/Cookie';
import Loading from '@/Services/Loading';
import Notify from '@/Services/Notify';

export { LeadControllerSingleton as default };

class LeadController {
  /**
   * http service 
   * @type {Object.<LeadEntity>}
   */
  entity = new LeadEntity;

  /**
   * http service
   * @type {Object.<LeadHTTP>}
   */
  http_service = new LeadHTTP;

  /**
   * http service
   * @type {Object.<LeadHTTPMapping>}
   */
  http_mapping = new LeadHTTPMapping;

  /**
   * @param {FormData}
   * @returns {Object.<LeadEntity>}
   */
  async onSaveLead(form_data) {
    this.entity.reset();

    form_data = this.handleFormDataRequest(form_data);

    Loading.show();
    const response = await this.http_service.saveLead(form_data);
    Loading.hide();

    if(!response.success){
      Notify.error(response.messages);
      return;
    }

    this.http_mapping.mapResponse(this.entity, response);
    Notify.success(response.messages);

    return this.entity;
  }

  /**
   * trata os dados antes de enviar para api
   * @private
   * @type {FormData}
   * @returns {FormData}
   */
  handleFormDataRequest(form_data){
    form_data.append('host', document.location.host);
    form_data.append('midia', Cookie.get('tech-midia'));
    form_data.append('gclid', Cookie.get('gclid'));
    form_data.append('utm_source', Cookie.get('utm_source'));
    form_data.append('utm_campaign', Cookie.get('utm_campaign'));
    form_data.append('utm_term', Cookie.get('utm_term'));
    form_data.append('utm_medium', Cookie.get('utm_medium'));
    form_data.append('utm_content', Cookie.get('utm_content'));
    form_data.append('fbclid', Cookie.get('fbclid'));
    let urlPath = '/' + document.location.pathname;
    urlPath = urlPath.replaceAll("//", "/");
    form_data.append('url_path', urlPath);

    return form_data;
  }
}

const LeadControllerSingleton = new LeadController;