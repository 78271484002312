import SimpleCollection from '@/Services/Collections/SimpleCollection';
import WhatsappEntity from '../Entities/WhatsappEntity';

export { WhatsappCollection as default };

class WhatsappCollection extends SimpleCollection {

  /**
   * Recupera o whatsapp ativo que está na pagina
   * atual
   *
   * @public
   */
  getActiveWhatsapp() {
    //TODO:
    const whatsapp = this.getFirst();

    return whatsapp;
  }

  /**
   * Mapea os campos do whatsapp na entity
   * colocando na lista de items
   *
   * @public
   * @param {Object|Array} data
   * @returns {void}
   */
  async mapCollection(data) {
    if (Array.isArray(data)) {
      this.mapFromArray(data);
      return;
    }

    this.add(this.mapEntity(data), data.phone);
  }


  mapFromArray() {
    //TODO:
  }

  /**
   * Mapea o objeto para a entity do whatsapp
   *
   * @private
   * @param {Object} data_object
   * @returns {Object.<WhatsappEntity>}
   */
  mapEntity(data_object) {
    const entity = new WhatsappEntity;
    entity.conversion_id = data_object.conversion_id;
    entity.conversion_label = data_object.conversion_label;
    entity.custom_selector = data_object.custom_selector;
    entity.departments = data_object.departments;
    entity.has_field_document = parseInt(data_object.has_field_document);
    entity.has_field_email = parseInt(data_object.has_field_email);
    entity.has_field_product = parseInt(data_object.has_field_product);
    entity.horizontal_space = parseInt(data_object.horizontal_space);
    entity.product_default = data_object.product_default;
    entity.html_button_native = data_object.html_button_native;
    entity.message = data_object.message;
    entity.phone = data_object.phone;
    entity.position = data_object.position;

    let direction = entity.position.split('_');
    entity.direction_horizontal = direction[1] || null;
    entity.direction_vertical = direction[0] || null;

    entity.products = data_object.products;
    entity.remove_button_native = data_object.remove_button_native;
    entity.type_document = data_object.type_document;
    entity.units = data_object.units;
    entity.use_button_float = parseInt(data_object.use_button_float);
    entity.use_custom_selector = parseInt(data_object.use_custom_selector);
    entity.vertical_space = parseInt(data_object.vertical_space);

    return entity;
  }
}