import AWN from "awesome-notifications"

export { NotifySingleton as default };

class Notify {
  notyf;

  constructor() {
    this.notyf = new AWN({
      icons: {enabled: false}
    })
  }

  /**
   * Exibe sucesso
   *
   * @public
   * @param {String} message
   * @returns {void}
   */
  success(message) {
    this.notyf.success(message);
  }

  /**
   * Exibe erro
   *
   * @public
   * @param {String} message
   * @returns {void}
   */
  error(message) {
    this.notyf.error(message);
  }
}

const NotifySingleton = new Notify;