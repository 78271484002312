import SimpleCollection from '@/Services/Collections/SimpleCollection';
import FormEntity from '../Entities/FormEntity';
import FieldFormEntity from '../Entities/FieldFormEntity';

export { FormCollection as default };

class FormCollection extends SimpleCollection {

  /**
   * Contagem de quantos formularios ativos
   * possuem
   *
   * @type {number}
   */
  form_active_count = 0;

  /**
   * Armazena todos os formulários que estao ativos
   * para integrar com a base de dados
   * @type {Array}
   */
  form_active_list = [];

  /**
   * Mapea os campos do forms na entity
   * colocando na lista de items
   *
   * @public
   * @param {Array} data
   * @returns {void}
   */
  async mapCollection(data) {
    if (!Array.isArray(data)) {
      return;
    }

    data.map(form => {
      this.add(this.mapEntity(form), form.identifier);
    });
  }

  /**
   * Mapea o formulario para entidade
   *
   * @private
   * @param {Object} object_data
   * @returns {FormEntity}
   */
  mapEntity(object_data) {
    const entity = new FormEntity;
    entity.button_submit = object_data.button_submit;
    entity.captation = object_data.captation;
    entity.conversion_id = object_data.conversion_id;
    entity.conversion_label = object_data.conversion_label;
    entity.department_id = object_data.department_id;
    entity.form_class = object_data.form_class;
    entity.has_change_input = parseInt(object_data.has_change_input);
    entity.html_style = object_data.html_style;
    entity.identifier = object_data.identifier;
    entity.is_active = parseInt((object_data.is_active || 0));
    entity.is_manual = parseInt(object_data.is_manual);
    entity.name = object_data.name;
    entity.plugin_id = parseInt(object_data.plugin_id);
    entity.sync_fields = parseInt(object_data.sync_fields);
    entity.unit_id = parseInt((object_data.unit_id || 0));
    entity.url = object_data.url;
    entity.url_redirect = object_data.url_redirect;

    this.mapFields(entity, object_data.fields);

    return entity;
  }

  /**
   * Mapea os campos que são referentes aos
   * campos do formulario, serao adicionados dentro
   * da colecao do formulario
   *
   * @private
   * @param {FormEntity} entity
   * @param {Array} fields
   * @returns {void}
   */
  mapFields(entity, fields) {
    fields.map(field => {
      const field_entity = new FieldFormEntity;
      field_entity.class = field.class;
      field_entity.field_system = field.field_system;
      field_entity.has_mask = parseInt((field.has_mask || 0));
      field_entity.has_required = parseInt((field.has_required || 0));
      field_entity.id = field.id;
      field_entity.name = field.name;
      field_entity.placeholder = field.placeholder;
      field_entity.type = field.type;

      let id = field_entity.id || Math.random().toString(36).substring(2, 7);

      entity.fields.add(field_entity, id)
    })
  }
}