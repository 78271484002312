import { cloneDeep } from 'lodash';
export { DeepInstanceSingleton as default }

class DeepInstance {
  clone(obj) {
    if (!obj) {
      return null;
    }

    return cloneDeep(obj);
  }
}

const DeepInstanceSingleton = new DeepInstance;