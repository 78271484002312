import modal_html from './HTML/modal-whatsapp.html';
import button_html from './HTML/button-whatsapp.html';
import WhatsappData from "./Entities/WhatsappData";
import DOMInstance from '@/Services/DOMInstance';
import Google from '@/Services/Google';
import Notify from '@/Services/Notify';
import InputPhone from '@/Services/Formulate/InputPhone';
import InputMask from '@/Services/Formulate/InputMask';
import LeadController from '@/Modules/Leads/LeadController';
import { nextTick } from '@/Services/Utils/NextTick';

export { WhatsAppSingleton as default };

class WhatsApp {
  /**
   * Entity que contém as configurações.
   */
  data = new WhatsappData();

  /**
   * Configuracao ativa do whatsapp
   * @type {Object.<WhatsappEntity>}
   */
  whatsapp_active;

  /**
   * Inicializa os eventos do Whatsapp
   * @public
   * @returns {void}
   */
  async initialize() {
    await this.data.mapFromAppEntity();

    if (!this.data.has_whatsapp) {
      console.log(`❓: Whatsapp not configured`);
      return;
    }

    if (!this.data.whatsapp.getActiveWhatsapp()) {
      console.log(`⭕: Whatsapp not active page`);
      return;
    }

    this.whatsapp_active = this.data.whatsapp.getActiveWhatsapp();

    await this.loadInsertHtml();
    await this.loadFieldMasks();
    await nextTick(1000);
    await this.loadEventListeners();

    console.log(`✅: Whatsapp loaded`);
  }

  /**
   * Carrega os HTMLs da pagina referente ao whatsapp
   * @public
   * @returns {void}
   */
  async loadInsertHtml() {
    this.insertButtonHtml();
    this.insertModalHtml();
  }

  /**
   * Carrega os campos que possuem mascaras
   * ou campos personalizados como telefone etc
   */
  async loadFieldMasks() {
    const masks = {
      cpf_cnpj: [
        { mask: '000.000.000-00' },
        { mask: '00.000.000/0000-00' },
      ],
    }
    const input_phone = new InputPhone();
    input_phone.initialize("#modal-wp-mobile", "#modal-wp-mobile-ddi");

    const input_document = new InputMask();
    const mask_options = masks[this.whatsapp_active.type_document] || null;
    input_document.applyMask('#field-whatsapp-document', mask_options);
  }

  /**
   * Submete o formulario do whatsapp para o servidor
   *
   * @public
   * @param {HTMLElement}
   * @returns {void}
   */
  async onSubmitForm(event) {
    event.preventDefault();
    const form_data = new FormData(event.target);
    const response = await LeadController.onSaveLead(form_data);

    Google.fireConversion(this.whatsapp_active.conversion_id, this.whatsapp_active.conversion_label);
    this.redirectAfterSubmit(form_data, response);
  }

  /**
   * Interacao do usuario quando clica para abrir o modal
   * @public
   * @returns {void}
   */
  onOpenModal() {
    const modal = document.querySelector("#modal-whatsapp-call");

    console.log(`✅: Clicked to open whatsapp:`, modal);

    if (!modal || modal.classList.contains('techmobi-open-modal')) {
      return;
    }

    modal.classList.add("techmobi-open-modal");
    DOMInstance.insertHtmlContent(`<div class="bootstrap-iso modal-backdrop show"></div>`);
  }

  /**
   * Interacao do usuario quando clica para fechar o modal
   * @public
   * @returns {void}
   */
  onCloseModal() {
    const modal = document.querySelector("#modal-whatsapp-call");

    if (!modal) {
      return;
    }

    modal.classList.remove("techmobi-open-modal");
    const backdrop = document.querySelector(".modal-backdrop");

    if (backdrop) {
      backdrop.remove();
    }
  }

  /**
   * Insere o html do Modal
   *
   * @returns {void}
   */
  insertModalHtml() {
    let html = modal_html;

    html = this.insertModalUnitHtml(html);
    html = this.insertModalDepartmentHtml(html);
    html = this.insertModalProductHtml(html);
    html = this.insertModalDocumentHtml(html);
    html = this.insertModalEmailHtml(html);

    html = html.replace("{{phone}}", this.whatsapp_active.phone);

    DOMInstance.insertHtmlContent(html);
  }

  /**
  * Insere o campo de produtos que possui
  * dropdown de opcoes ou input texto
  *
  * @param {String} html
  * @returns {String}
  */
  insertModalProductHtml(html) {
    if (this.whatsapp_active.product_default) {
      return html.replace("{{field_product}}", `<input type="hidden" name="product"
        value="${this.whatsapp_active.product_default}" />`);
    }

    if (!this.whatsapp_active.has_field_product) {
      return html.replace("{{field_product}}", "");
    }

    if (!this.whatsapp_active.products.length) {
      let field_html = `<input class="form-control" type="text" name="product" placeholder="Interesse"
      style="border: 1px solid #bacac6" required="true" />`;
      return this.insertModalFieldHtml(html, field_html, 'product', 'Interesse:')
    }

    const products = this.whatsapp_active.products.map(ele => {
      return {
        id: ele.name,
        whatsapp: '',
        name: ele.name,
      }
    });
    let field_html = `<select class="form-control" id="modal-wp-product" name="product"
      style="border:1px solid #bacac6;" required="true">
      <option value="">---</option>
      ${this.generateDropdownHtml(products)}
    </select>`;

    return this.insertModalFieldHtml(html, field_html, 'product', 'Interesse:');
  }

  insertModalEmailHtml(html) {
    if (this.whatsapp_active.has_field_email === 0) {
      return html.replace("{{field_email}}", " ");
    }

    let field_html = `<div class="row">
      <div class="col-sm-12">
        <label>E-mail</label>
        <input class="form-control" type="text" name="email" placeholder="E-mail para contato"
          required="true" />
      </div>
    </div>`;
    return html.replace(`{{field_email}}`, field_html);
  }

  /**
   * Carrega o campo que é documento
   *
   * @private
   * @param {String} html
   * @returns {String}
   */
  insertModalDocumentHtml(html) {
    if (!this.whatsapp_active.has_field_document) {
      return html.replace("{{field_document}}", "");
    }

    let field_html = `<input type="hidden"
      name="type_document"
      id="field-whatsapp-type_document"
      value="`+ this.whatsapp_active.type_document + `" />
    <input class="form-control" type="text"
    id="field-whatsapp-document" name="document"
    placeholder="Nº do Documento" required="true" />`;
    return this.insertModalFieldHtml(html, field_html, 'document', 'CPF/CNPJ');
  }

  /**
   * Insere o campo de unidade que possui
   * dropdown de opcoes
   *
   * @param {String} html
   * @returns {String}
   */
  insertModalUnitHtml(html) {
    if (this.whatsapp_active.units.length === 0) {
      return html.replace("{{field_unit_id}}", "");
    }

    if (this.whatsapp_active.units.length === 1) {
      return html.replace('{{field_unit_id}}', `<input type="hidden" name="unit_id" value="${this.whatsapp_active.units[0].id}" />`)
    }

    let field_html = `<select class="form-control" id="modal-wp-unit_id" name="unit_id"
      style="border:1px solid #bacac6;" required="true">
      <option value="">---</option>
      ${this.generateDropdownHtml(this.whatsapp_active.units)}
    </select>`;

    return this.insertModalFieldHtml(html, field_html, 'unit_id', 'Unidade:');
  }

  /**
   * Insere o campo de departamento que possui
   * dropdown de opcoes
   *
   * @param {String} html
   * @returns {String}
   */
  insertModalDepartmentHtml(html) {
    if (this.whatsapp_active.departments.length === 0) {
      return html.replace('{{field_department_id}}', `<input type="hidden" name="department_id" value="Comercial" />`)
    }

    if (this.whatsapp_active.departments.length === 1) {
      return html.replace('{{field_department_id}}', `<input type="hidden" name="department_id" value="${this.whatsapp_active.departments[0].id}" />`)
    }

    let field_html = `<select class="form-control" id="modal-wp-department_id" name="department_id"
      style="border:1px solid #bacac6;" required="true">
      <option value="">---</option>
      ${this.generateDropdownHtml(this.whatsapp_active.departments)}
    </select>`;

    return this.insertModalFieldHtml(html, field_html, 'department_id', 'Departamento');
  }

  /**
   * Insere uma linha que contém um input no html
   *
   * @private
   * @param {String} html
   * @param {String} field_html
   * @param {String} field_name
   * @param {String} label
   * @returns  {String}
   */
  insertModalFieldHtml(html, field_html, field_name, label) {
    return html.replace(`{{field_${field_name}}}`, `
      <div class="row">
        <div class="col-sm-12">
          <label>${label}:</label>
          ${field_html}
        </div>
      </div>`
    );
  }

  /**
   * Carrega o botão da página
   * @private
   * @returns {void}
   */
  insertButtonHtml() {
    let html = button_html.replace('{{direction_horizontal}}', this.whatsapp_active.direction_horizontal)
      .replace('{{direction_vertical}}', this.whatsapp_active.direction_vertical);

    DOMInstance.insertHtmlContent(html);
  }

  /**
   * Evento quando o campo de unidade é trocado
   * invoca atualizacoes
   *
   * @public
   * @returns {void}
   */
  onChangeUnit(event) {
    const dropdown_department = document.querySelector("#modal-wp-department_id");
    const input_whatsapp_to = document.querySelector("#modal-wp-whatsapp_to");

    let selected_unit = event.target.options[event.target.selectedIndex];
    if (selected_unit.getAttribute('data-whatsapp')) {
      input_whatsapp_to.value = selected_unit.getAttribute('data-whatsapp');
    }

    if (!dropdown_department) {
      return;
    }

    let department_options = '<option value="">---</option>';
    department_options += this.generateDropdownHtml(this.whatsapp_active.departments);
    dropdown_department.innerHTML = department_options;
  }

  /**
   * Evento quando o campo de departamento é trocado
   * invoca atualizacoes
   *
   * @public
   * @returns {void}
   */
  onChangeDepartment(event) {
    let selected = event.target.options[event.target.selectedIndex];
    if (selected.getAttribute('data-whatsapp')) {
      document.querySelector("#modal-wp-whatsapp_to").value = selected.getAttribute('data-whatsapp');
    }
  }

  /**
   * Gera as opcoes do dropdown que serao inseridas no html
   * @param {Array} list_data
   * @returns {String}
   */
  generateDropdownHtml(list_data) {
    let select_html = '';
    list_data.map((elem, index) => {
      select_html += `<option value="${elem.id}" data-whatsapp="${elem.whatsapp}">${elem.name}</option>`
    });

    return select_html;
  }

  /**
   * Depois de submeter o lead faz as trativas
   * de redirect
   *
   * @private
   * @returns {void}
   */
  async redirectAfterSubmit(form_data, lead) {
    let whatsapp_to = form_data.get('whatsapp_to');
    whatsapp_to = whatsapp_to.replace(/\D/, '');
    let url_redirect = '';
    let message = this.whatsapp_active.message.replaceAll(':product', form_data.get('product'));

    url_redirect = `https://api.whatsapp.com/send?l=pt`
    url_redirect += `&phone=55${whatsapp_to}`;
    url_redirect += `&text=${message}`;

    if (this.data.auto_allocate_whatsapp && lead.phone_whatsapp) {
      let phone = lead.phone_whatsapp.replace(/\D/, '');
      url_redirect = `https://api.whatsapp.com/send?l=pt`;
      url_redirect += `&phone=55${phone}`;
      url_redirect += `&text=${message}`;
    }

    Notify.success("Estamos conectando e iremos redirecionar você para o whatsapp...");

    await nextTick(2000);
    document.location.href = url_redirect;
  }

  /**
   * Carrega os eventos que os botoes do whatsapp
   *
   * @private
   * @returns {void}
   */
  async loadEventListeners() {
    const button_close = document.querySelector(".techmobi-whatsapp__close");
    DOMInstance.addEventListener(button_close, "click", this.onCloseModal.bind(this))

    if (this.whatsapp_active.use_button_float) {
      const button_float = document.querySelector(".techmobi__button");
      DOMInstance.addEventListener(button_float, "click", this.onOpenModal.bind(this))
    }

    if (this.whatsapp_active.use_custom_selector) {
      DOMInstance.addEventListerElements(this.whatsapp_active.custom_selector, "click", this.onOpenModal.bind(this));
      DOMInstance.addAttributeElement(this.whatsapp_active.custom_selector, 'href', 'javascript:;')
    }

    if (this.whatsapp_active.remove_button_native) {
      DOMInstance.removeMultipleElement(this.whatsapp_active.html_button_native);
    }

    /** default adiciona em todos campos que são links */
    DOMInstance.addEventListerElements('a[href*="api.whatsapp"], a[href*="wa.me"]', "click", this.onOpenModal.bind(this));
    DOMInstance.addEventListener(document.querySelector("#techmobi-form--whatsapp"), "submit", this.onSubmitForm.bind(this))
    DOMInstance.addEventListener(document.querySelector("#modal-wp-unit_id"), "change", this.onChangeUnit.bind(this));
    DOMInstance.addEventListener(document.querySelector("#modal-wp-department_id"), "change", this.onChangeDepartment.bind(this));
    //atualiza os atributos de links
    DOMInstance.addAttributeElement('a[href*="api.whatsapp"], a[href*="wa.me"]', 'href', 'javascript:;')
  }

}

const WhatsAppSingleton = new WhatsApp;