export { AppEntitySingleton as default };

class AppEntity {
  auto_identifier_forms;
  auto_allocate_whatsapp;
  type_allocate_whatsapp;
  chatbot;
  forms;
  google_adwords = [];
  google_analytics = [];
  has_custom_styles = 0;
  has_forms = 0;
  has_integrations = 0;
  has_whatsapp = 0;
  ignore_forms_url;
  recaptcha_public;
  use_recaptcha = 0;
  whatsapp;
}

const AppEntitySingleton = new AppEntity;