import DOMInstance from "@/Services/DOMInstance";
import WhatsApp from "../Whatsapp/WhatsApp";
import AppEntity from "./Entities/AppEntity";
import AppHTTP from "./HTTP/AppHTTP";
import AppHTTPMapping from "./HTTP/AppHTTPMapping";
import Cookie from '@/Services/Cookie';
import Formulate from "../Formulate/Formulate";

export { AppControllerSingleton as default };

class AppController {
  /**
   * @type {Object}
   */
  entity = AppEntity;

  /**
   * @type {Object.<AppHTTP>}
   */
  http_service = new AppHTTP;

  /**
   * @type {Object.<AppHTTPMapping>}
   */
  http_mapping = new AppHTTPMapping;

  /**
   * inicializa as funções e classes
   * @public
   * @returns {void}
   */
  async initialize() {
    await this.loadConfigurations();
    DOMInstance.initialize();
    Cookie.initialize();
    WhatsApp.initialize();
    Formulate.initialize();
  }

  /**
   * Carrega os dados de configuracoes para o site
   *
   * @private
   * @returns {void}
   */
  async loadConfigurations() {
    const params = {
      host: window.location.host,
    };

    const response = await this.http_service.onLoadConfigurations(params);

    if (!response.success) {
      console.error(`😞: ${response.messages}`)
      return;
    }

    this.http_mapping.mapConfigurations(this.entity, response.results);
  }
}

const AppControllerSingleton = new AppController;