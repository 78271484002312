import FormCollection from "../Collections/FormCollection";
import AppController from '@/Modules/App/AppController';
import FormEntity from "./FormEntity";

export { FormulateData as default };

class FormulateData {
  /**
   * Flag de se o site possui formularios
   * 
   * @type {Number}
   */
  has_forms = 0;

  /**
   * Se for para identificar automaticamente
   * os formulários do site e registrar no
   * Backoffice esses formularios
   * 
   * @type {Number}
   */
  auto_identifier_forms = 0;

  /**
   * Paginas que não será feito a sincronizacao
   * de formularios, no caso serao ignorados
   * qualquer tipo de formulario.
   *
   * @type {Array|null}
   */
  ignore_forms_url = [];

  /**
   * Lista de formulários do stie
   *
   * @type {FormCollection}
   */
  forms = new FormCollection;

  /**
   * Mapea os campos que estao vindo do AppEntity
   *
   * @returns {void}
   */
  async mapFromAppEntity() {
    this.has_forms = parseInt(AppController.entity.has_forms);
    this.auto_identifier_forms = parseInt(AppController.entity.auto_identifier_forms);
    this.ignore_forms_url = AppController.entity.ignore_forms_url;

    if (!this.has_forms) {
      return;
    }

    await this.forms.mapCollection(AppController.entity.forms);
  }

  /**
   * Quando roda a sincronizacao na pagina do html
   * e é listado todos os formulários que a pagina possui
   * é identificado se esse formulário existe na base (esta na colecao de forms atual)
   * se esse valor nao existe adicionamos o fomrulario na lista e depois adicionamos
   * na lista de sync, entretanto esse formulário entra na base de dados que possui
   *
   * @param {*} form_locale_page
   *
   * @returns {void}
   */
  async forceAddForm(form_locale_page) {
    this.forms.add(form_locale_page, form_locale_page.identifier);
  }

  /**
   * Adiciona o formulário na lista de forms ativos que serão
   * carregados na pagina criando o evento de conexao com a base
   * de dados
   *
   * @param {FormEntity} form
   * @returns
   */
  async addFormActive(form) {
    if (!form.is_active) {
      return;
    }

    this.forms.form_active_count++;
    this.forms.form_active_list.push(form);
  }
}