export { WhatsappEntity as default };

class WhatsappEntity {
  /**
   * google conversion id
   * @type {String}
   */
  conversion_id;
  /**
   * google conversion label
   * @type {String}
   */
  conversion_label;
  /**
   * Codigo html que contem o seletor
   * @type {String}
   */
  custom_selector;
  /**
   * Lista de departamentos
   * @type {Array}
   */
  departments = [];
  /**
   * Possui campo de documento?
   * @type {Number}
   */
  has_field_document = 0;
  /**
   * Campo de email?
   * @type {Number}
   */
  has_field_email = 0;
  /**
   * Campo de produto
   * @type {Number}
   */
  has_field_product = 0;

  /**
   *
   */
  product_default;
  /**
   * Space horizontal
   * @type {Number}
   */
  horizontal_space = 40;
  /**
   * Codigo que identifica o botao nativo
   * @type {String}
   */
  html_button_native;
  /**
   * Mensagem de whatsapp
   * @type {String}
   */
  message;
  /**
   * phone redirect
   * @type {String}
   */
  phone;
  /**
   * Posicao onde o botao ficara
   * @type {String}
   */
  position;
  /**
   * Direção se é bottom ou top
   * @type {String}
   */
  direction_vertical;
  /**
   * Direction se é esquerdo ou direito
   * @type {String}
   */
  direction_horizontal;
  /**
   * lista de produtos
   * @type {String}
   */
  products = [];
  /**
   * Remove o botao nativo do site?
   * @type {Number}
   */
  remove_button_native;
  /**
   * identifica o tipo do documento que 
   * sera usado no campo de documento
   * 
   * @type {String}
   */
  type_document;
  /**
   * Lista de unidades
   * @type {Array}
   */
  units = [];
  /**
   * Usar o botao flutuante?
   * @type {Number}
   */
  use_button_float = 0;
  /**
   * Utilizar um seletor customizado?
   * @type {Number}
   */
  use_custom_selector = 0;
  /**
   * Espaço vertical do botao
   * @type {Number}
   */
  vertical_space = 10;
}