export { GoogleSingleton as default };

class Google {

  /**
   * Dispara um evento para o Google de conversao
   * pelo gtag, se tiver erro tenta por poup forçando
   * uma inclusão
   *
   * @public
   * @param {String} conversion_id
   * @param {String} conversion_label
   * @returns {void}
   */
  fireConversion(conversion_id, conversion_label) {
    if (!conversion_id) {
      return;
    }

    let conversionString = `AW-${conversion_id}/${conversion_label}`;

    try {
      gtag('event', 'conversion', {
        'send_to': conversionString,
      });
    } catch (e) {
      this.fireConversionPopup(conversion_id, conversion_label);
    }
  }

  /**
   * Dispara um evento do google com Popup
   * Esse evento é disparado caso o evento do GTAG nao existir
   * Mas nao impede de ter chamado direto
   *
   * @public
   * @param {String} conversion_id
   * @param {String} conversion_label
   * @returns {void}
   */
  fireConversionPopup(conversion_id, conversion_label) {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://www.googleadservices.com/pagead/conversion.js`;
    script.async = true;

    script.innerHTML = `var google_conversion_id = "${conversion_id}"; var google_conversion_label = "${conversion_label}"; var google_remarketing_only = false;`;

    document.head.appendChild(script);

    script.onload = function () {
      console.log(`loaded conversion: ${conversion_id}/${conversion_label}`);
    };
  }
}

const GoogleSingleton = new Google();