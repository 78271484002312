import FormulateData from "./Entities/FormulateData";
import SimpleCollection from '@/Services/Collections/SimpleCollection'
import Crypto from 'crypto-js';
import FormEntity from "./Entities/FormEntity";
import FieldFormEntity from "./Entities/FieldFormEntity";
import FormulateHTTP from "./HTTP/FormulateHTTP";
import DOMInstance from "@/Services/DOMInstance";
import LeadController from "../Leads/LeadController";
import Google from '@/Services/Google';
import InputPhone from "../../Services/Formulate/InputPhone";

export { FormulateSingleton as default };

/**
 * @class Responsável em gerir as interações
 * dos formulários que contém na pagina do site
 *
 * @author Roni Sommerfeld <roni@4tech.mobi>
 */
class Formulate {

  http_service = new FormulateHTTP;

  /**
   * Entity FormulateDat
   * @type {FormulateData}
   */
  data = new FormulateData;

  /**
   * Colecao que trata de salvar os
   * formularios da pagina
   */
  collection_forms = new SimpleCollection();

  /**
   * Colecao que lista os formularios que precisam
   * fazer sync com a base
   */
  collection_forms_sync = new SimpleCollection();

  /**
   * Inicializa as configurações do Formualte
   * @returns {void}
   */
  async initialize() {
    await this.data.mapFromAppEntity();

    if (!this.data.has_forms || (await this.checkIfIgnorePage())) {
      return;
    }

    await this.handleLoadFormulate();
    await this.startFormulateWithIntegration();

    console.log(`✅: Formulates loaded`);
  }

  /**
   * Interage na sequencia de execucoes
   */
  async handleLoadFormulate() {
    await this.listFormsPage();
    await this.matchLocalWithData();
    await this.syncFormsLocale();
  }

  /**
   * Instancia os formulários que possuem a integração com a base
   * ativando assim os eventos pertinentes que possuem.
   */
  async startFormulateWithIntegration() {
    if (!this.data.forms.form_active_count) {
      return;
    }

    for (let index = 0; index < this.data.forms.form_active_count; index++) {
      const form = this.data.forms.form_active_list[index];

      await this.changeTagNameForm(form);
      await this.addFieldsInForm(form);
      await this.changeFieldsNameForm(form);
      this.removeEventElementForm(form);
      this.addEventListenerForm(form);
      this.addEventListenerButtons(form);
      this.addMaskInFields(form);
    }
  }

  async onSubmitForm(event) {
    try {
      const $form_element = event.target.form;

      if (!$form_element) return;

      event.preventDefault();

      if (!$form_element.checkValidity()) {
        $form_element.reportValidity();
        return;
      }

      // Ignore forms that are actively being submitted
      if ($form_element.classList.contains('submitting')) return;

      $form_element.classList.add('submitting');
      const form_data = new FormData($form_element);
      const response_lead = await LeadController.onSaveLead(form_data);

      if (!response_lead.id) return;

      this.afterSubmitForm($form_element);
    } catch (error) {
      $form_element.classList.remove('submitting');
    }
  }

  /**
   * Depois que o formulário feito o submit
   * com sucesso fazemos algumas validacoes ou
   * ações
   *
   * @param {HTMLElement} $form_element
   */
  afterSubmitForm($form_element) {
    const form_props = this.data.forms.get($form_element.getAttribute('data-identifier'));
    Google.fireConversion(form_props.conversion_id, form_props.conversion_label);

    $form_element.reset();
    $form_element.classList.remove('submitting');

    if ($form_element.getAttribute('data-redirect')) {
      setTimeout(() => {
        document.location.href = $form_element.getAttribute('data-redirect');
      }, 700);
    }
  }

  /**
   * Existe situações que o conteudo não é um <form>
   * dessa maneira garantimos que o conteudo seja form
   */
  async changeTagNameForm(form) {
    if (form.$element.nodeName.toLowerCase() === 'form') {
      return;
    }

    await DOMInstance.changeTagName(form.$element, 'form');
  }

  /**
   * Adiciona mascaras nos campos que possuem
   * Porem só é adicionado para os campos que
   * fazem parte do sistema e que a flag de mask esta
   * ativa
   *
   * @param {FormEntity} form
   */
  async addMaskInFields(form) {
    for (let index = 0; index < form.fields.count; index++) {
      const field = form.fields.items[index];

      if (!field.has_mask || !field.field_system) {
        continue;
      }

      await this.applyMaskInField(form, field);
    }
  }

  /**
   * Adiciona a mascara no campo.
   *
   * @private
   * @param {FormEntity} form
   * @param {FieldFormEntity} field
   *
   * @returns {void}
   */
  async applyMaskInField(form, field) {
    if (['phone', 'mobile'].includes(field.field_system)) {
      const input_phone = new InputPhone;
      input_phone.initialize(`[name=${field.name}]`, `[name=${field.field_system}_ddi]`, form.$element);
    }
  }

  /**
   * Adiciona novos campos ao formulário que são
   * padroes para o funcionamento
   *
   * @private
   * @param {FormEntity} $form
   *
   * @returns {void}
   */
  addFieldsInForm(form) {
    DOMInstance.insertHtmlToElement(form.$element, 'input', 'hidden', 'mobile_ddi');
    DOMInstance.insertHtmlToElement(form.$element, 'input', 'hidden', 'phone_ddi');
    const captation = form.captation || 'form-site';
    DOMInstance.insertHtmlToElement(form.$element, 'input', 'hidden', 'captation', captation);
    const unit_id = form.unit_id || '';
    DOMInstance.insertHtmlToElement(form.$element, 'input', 'hidden', 'unit', unit_id);
    const department_id = form.department_id || '';
    DOMInstance.insertHtmlToElement(form.$element, 'input', 'hidden', 'department', department_id);
  }

  /**
   * Existe situações que é necessário alterar o nome dos
   * campos que foram dado match do sistema da base
   * refletindo no formulário ou ate mesmo por que o formulário
   * nao possui os nomes de campos
   *
   * @param {FormEntity} $form
   *
   * @returns {void}
   */
  changeFieldsNameForm(form) {
    if (!form.has_change_input) {
      return;
    }
    //TODO:
  }

  /**
   * Adiciona evetos no formulario
   *
   * @param {FormEntity} form
   */
  addEventListenerForm(form) {
    form.$element.setAttribute('data-redirect', (form.url_redirect || ''));
    DOMInstance.addEventListener(form.$element, 'submit', this.onSubmitForm.bind(this));
  }

  /**
   * Remove eventos e elemntos com atributos que estao no formulario
   *
   * @param {FormEntity} form
   */
  removeEventElementForm(form) {
    form.$element.removeAttribute('action');
    form.$element.setAttribute('data-identifier', form.identifier);
    form.$element.removeEventListener('submit', function () { });
  }

  /**
   * Adiciona eventos nos botoes do formulario
   *
   * @param {FormEntity} form
   * @returns
   */
  addEventListenerButtons(form) {
    const buttons = form.$element.querySelectorAll('button, button[type=submit], input[type=submit], input[type=button]');

    if (!buttons.length) {
      return;
    }

    buttons.forEach(button => {
      button.removeAttribute('onclick');
      button.setAttribute('type', 'button');
      DOMInstance.addEventListener(button, 'click', this.onSubmitForm.bind(this));
    });
  }

  /**
   * Lista e Identifica se a página possui formulário, se
   * tiver ele adiciona na coleção de formularios da página
   * que será usado para identificar depois os dados
   *
   * @returns {void}
   */
  async listFormsPage() {
    const element_forms = document.querySelectorAll("form");

    for (const [form_index, element] of element_forms.entries()) {
      if (element.classList.contains('form-whatsapp-techmobi')) {
        continue;
      }

      const form_entity = this.mappingFormEntity(element);
      this.mappingFieldsFromForm(element, form_entity);

      const identifier = form_entity.id
        ? Crypto.MD5(form_entity.id)
        : Crypto.MD5(JSON.stringify(form_entity.fields.items.map(f => f.name)));
      form_entity.identifier = identifier.toString();

      this.collection_forms.add(form_entity, form_entity.identifier);
    }
  }

  /**
   * Intera na colecao dos forms q foram carregados na pagina
   * com os formularios que vieram da api
   * identificando qual que está salvo e qual que não está
   *
   * @private
   * @returns {void}
   */
  async matchLocalWithData() {
    for (let index = 0; index < this.collection_forms.count; index++) {
      const form_locale_page = this.collection_forms.getByIndex(index);
      const form_saved = this.data.forms.get(form_locale_page.identifier);

      //formulario nao existe na base
      if (!form_saved) {
        await this.data.forceAddForm(form_locale_page);
        await this.data.addFormActive(form_locale_page);
        this.addFormToSync(form_locale_page);

        continue;
      }

      //formulario existe na base adiciona o elemento
      this.data.forms.index[form_locale_page.identifier].$element = form_locale_page.$element;
      this.data.addFormActive(this.data.forms.index[form_locale_page.identifier]);

      //se o formulario existe na base em segundo acesso verifica se tem force sync
      if (form_saved?.sync_fields) {
        this.addFormToSync(form_locale_page)
      }
    }
  }

  /**
   * Adiciona o formulário na lista para sincronizar
   * os dados com a base de dados
   *
   * @param {FormEntity} form
   */
  addFormToSync(form) {
    this.collection_forms_sync.add(form, form.identifier);
  }

  /**
   * Salva os formularios que estao na collecao de
   * forms da pagina com a base de dados
   *
   * @private
   * @returns {void}
   */
  async syncFormsLocale() {
    for (let index = 0; index < this.collection_forms_sync.count; index++) {
      await this.http_service.onSyncFormulatePages(this.collection_forms_sync.getByIndex(index));
    }
  }

  /**
   * Mapea os campos do formulário para uma entidade
   * de form
   *
   * @param {HTMLElement} element_form
   * @param {FormEntity} form_entity
   */
  mappingFieldsFromForm(element_form, form_entity) {
    const fields = element_form.getElementsByTagName('input', 'select', 'textarea');

    for (const element_field of fields) {
      const field = new FieldFormEntity;
      field.name = element_field.getAttribute('name') || element_field.getAttribute('id');
      field.type = element_field.nodeName.toLowerCase();
      field.id = element_field.getAttribute('id') || '';
      field.placeholder = element_field.getAttribute('placeholder') || '';
      field.has_required = element_field.getAttribute('required');
      field.class = element_field.getAttribute('class');
      //fix name to placeholder in case empty
      if (!field.name) {
        field.name = field.placeholder;
      }

      let id = field.id || Math.random().toString(36).substring(2, 7);

      form_entity.fields.add(field, id);
    }
  }


  /**
   * Identifica se a página atual
   * deverá ser ignorada por que está na
   * lista de paginas ignoradas
   *
   * @public
   * @returns {Boolean}
   */
  async checkIfIgnorePage() {
    if (!this.data.ignore_forms_url || !Array.isArray(this.data.ignore_forms_url)) {
      return false;
    }

    const promises = this.data.ignore_forms_url.map(url_ignore => {
      return new Promise(resolve => {
        if (url_ignore.includes(document.location.pathname)) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });

    const results = await Promise.all(promises);
    return results.includes(true);
  }

  mappingFormEntity(element) {
    const entity = new FormEntity;
    entity.$element = element;
    entity.form_class = element.getAttribute('class');
    entity.form_id = element.getAttribute('id');

    return entity;
  }
}

const FormulateSingleton = new Formulate;