import intlTelInput from 'intl-tel-input';
import DOMInstance from '../DOMInstance';

export { InputPhone as default };

class InputPhone {
  /**
   * Elemento Input do campo de telefone
   * @type {HTMLElement}
   */
  input_mobile;
  /**
   * Elemento Input do campo de ddi
   * @type {HTMLElement}
   */
  input_mobile_country;
  /**
   * Class intlTelInput
   * @type {Object.<intlTelInput>}
   */
  input_intl;

  /**
   * Inicializa o campo de telefone
   * @public
   * @param {String} input_mobile ID ou class elemento
   * @param {String} input_mobile_country ID ou class elemento
   * @returns {void}
   */
  initialize(input_mobile, input_mobile_country, $element = document) {
    this.input_mobile = $element.querySelector(input_mobile);
    this.input_mobile_country = $element.querySelector(input_mobile_country);

    if (!this.input_mobile) {
      return;
    }

    DOMInstance.insertStyleHead('https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/css/intlTelInput.css');
    this.changeTypeInputMobile();
    this.applyMask();
  }

  /**
   * Aplica a mascara no campo de telefone
   *
   * @private
   * @returns {void}
   */
  applyMask() {
    const self = this;

    this.input_intl = intlTelInput(this.input_mobile, {
      initialCountry: DOMInstance.getCountryByLang(),
      formatOnDisplay: true,
      // placeholderNumberType: 'MOBILE',
      autoPlaceholder: 'polite',
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.14/js/utils.js"
    });

    this.input_intl.promise.then(function () {
      self.setValueInputMobileCountry(self.input_intl.getSelectedCountryData().dialCode);
      self.addEventInputMobile();
    });
  }

  /**
   * Adiciona eventos no campo de telefone
   * @private
   * @returns {void}
   */
  addEventInputMobile() {
    const self = this;
    self.input_mobile.addEventListener('countrychange', () => {
      self.setValueInputMobileCountry(this.input_intl.getSelectedCountryData().dialCode);
    })
  }

  /**
   * Adiciona um valor no input que contem o mobile_ddi
   * @public
   * @returns {void}
   */
  setValueInputMobileCountry(value) {
    if (!this.input_mobile_country) {
      return;
    }

    this.input_mobile_country.value = value;
  }

  /**
   * Em alguns casos o campo de telefone é tipo number
   * para a lib funcionar precisa ser texto
   * pequeno fix para essa situacao
   *
   * @private
   * @returns {void}
   */
  changeTypeInputMobile() {
    this.input_mobile.setAttribute('type', 'text');
    this.input_mobile.setAttribute('placeholder', '(99) 99999-9999');
  }
}