import HTTPService from "@/Services/HTTP/HTTPService";

export { AppHTTP as default };

class AppHTTP {
  /**
   * endpoint api
   * @private
   *
   * @type {String}
   */
  endpoint = `${process.env.API_URL}/api/v2/marketing/leads/configurations`;

  /**
   * Fetch Data Config
   *
   * @public
   * @returns {Object.<HTTPResponse>}
   */
  async onLoadConfigurations(params) {
    let response = await HTTPService.get(
      `${this.endpoint}`,
      params
    );

    return response;
  }
}