import SimpleCollection from '@/Services/Collections/SimpleCollection';

export { FormEntity as default };

class FormEntity {
  /**
   * Esse é o elemento do formulário no HTML
   * quando o formulário é sincronizado ou identificado
   * colocamos o elemento do formulário nesse campo
   *
   * @type {HTMLELement|null}
   */
  $element = null;
  /**
   * Em alguns formulários não exsite botao submit
   * ou nao é dentro de um <form> sendo assim identifica
   * o ID ou Class de um botão que está no html
   *
   * @type {String}
   */
  button_submit;
  /**
   * Captacao que sera feita para o formulario
   *
   * @type {String}
   */
  captation;
  /**
   * Conversao Google ID
   *
   * @type {String}
   */
  conversion_id;
  /**
   * Identifica se os campos do formulário
   * serão substituidos pelos nomes do sistema
   * por exemplo, se no formulario o campo NAME
   * esta com o tag name=vg_name, sera substituido para
   * name=name, isso força para identificacao correta no bakcend
   *
   * @type {Number}
   */
  has_change_input = 0;
  /**
   * Conversao Google Label
   *
   * @type {String}
   */
  conversion_label;
  /**
   * Departamento padrao que
   * sera feito a conversao
   *
   * @type {Number}
   */
  department_id;
  /**
   * Campos que o formulário possui
   * Nesse caso aqui contem uma lista
   * de todos os campos
   *
   * @type {Object.<SimpleCollection>}
   */
  fields = new SimpleCollection;
  /**
   * Identificação da classe que o formulario
   * está usando, geralmente usado como base
   * para instanciar o evento de submit para API
   * do nosso sistema.
   *
   * @type {String}
   */
  form_class;
  /**
   * Assim como form_class é um identificador que
   * poderá ser usado para add eventos
   *
   * @type {String}
   */
  form_id;
  /**
   * ID do formulário no sistema
   *
   * @type {Number}
   */
  id;
  /**
   * Estilo customizado que poderá aplicar na pagina do cliente
   * para alterar o estilo que tiver
   *
   * @type {String}
   */
  html_style;
  /**
   * Identificador que é criado automaticamente para o formulario
   *
   * @type {String}
   */
  identifier;
  /**
   * Identifica se o formulario esta ativo ou nao para
   * adicionar os eventos de submit para API
   *
   * @type {Number}
   */
  is_active = 1;
  /**
   * Identifica se o formulário foi criado manualmente pelo
   * backoffice sendo assim leva em conta situacoes q precisarem
   *
   * @type {String}
   */
  is_manual;
  /**
   * Identificacao do formulario (nome)
   *
   * @type {String}
   */
  name;
  /**
   * Plugin de configuracoes
   *
   * @type {Number}
   */
  plugin_id;
  /**
   * Os campos foram sincronizados
   * 0 = nao tem sincronizacao , 1 = tem sincronizacao
   * @type {Number}
   */
  sync_fields;
  /**
   * Unidade padrão que será feito a conversao
   * se for null segue o padrao do sistema
   *
   * @type {Number|null}
   */
  unit_id;
  /**
   * Identifica se o formulario está presente
   * em todas as paginas, pode ser array ou string
   * contendo apenas (*), que representa todas paginas
   *
   * @type {Array|String}
   */
  url;
  /**
   * Permite redirecionar o usuario para uma pagina
   * depois de submeter o formulario
   *
   * @type {String}
   */
  url_redirect;
}