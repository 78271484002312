export { LeadEntity as default };

class LeadEntity{
  /**
   * ID do Lead
   * @type {Number|null}
   */
  id = null;
  /**
   * ID do Lead pai
   * @type {Number|null}
   */
  parent_id = null;
  /**
   * Usuario que vai atender
   * @type {String|null}
   */
  user = null;
  /**
   * Numero do whatsapp do usuario
   * @type {String|null}
   */
  phone_whatsapp = null;

  reset(){
    this.id = null;
    this.parent_id = null;
    this.user = null;
    this.phone_whatsapp = null;
  }
}