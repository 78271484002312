import 'js-loading-overlay';

export { LoadingSingleton as default };

class Loading {
  /**
   * exibe o loading full page
   *
   * @public
   * @returns {void}
   */
  show() {
    JsLoadingOverlay.show({
      'overlayBackgroundColor': '#666666',
      'overlayOpacity': 0.6,
      'spinnerIcon': 'ball-atom',
      'spinnerColor': '#000',
      'spinnerSize': '2x',
      'overlayIDName': 'overlay',
      'spinnerIDName': 'spinner',
      'spinnerZIndex': 999999,
      'overlayZIndex': 999998
    });
  }

  /**
   * oculta o loading full page
   * @public
   * @returns {void}
   */
  hide() {
    JsLoadingOverlay.hide();
  }
}

const LoadingSingleton = new Loading;