import HTTPService from "@/Services/HTTP/HTTPService";

export { FormulateHTTP as default };

class FormulateHTTP {
  /**
   * endpoint api
   * @private
   *
   * @type {String}
   */
  endpoint = `${process.env.API_URL}/api/v2/marketing/formulate`;

  /**
   * Salva os dados do formularios e sincroniza
   *
   * @public
   * @returns {Object.<HTTPResponse>}
   */
  async onSyncFormulatePages(form) {
    const payload = {
      host: document.location.host,
      payload: this.mapFormToObject(form),
    }

    let response = await HTTPService.post(
      `${this.endpoint}`,
      payload,
      'sync_fetch_forms'
    );

    return response;
  }

  mapFormToObject(form) {
    const params = form;
    params.fields = form.fields.items;
    delete params.$element;

    return params;
  }
}