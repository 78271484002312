export { FieldFormEntity as default };

class FieldFormEntity {
  /**
   * Classe que o campo poderá ter
   * 
   * @type {String}
   */
  class;
  /**
   * Identifica se esse campo é um
   * campo que é parte do sistema
   * se existir valor aqui esse valor
   * será usado para adicionar na tag
   * name do formulario ou do id do form
   * 
   * @type {String}
   */
  field_system;
  /**
   * Identifica se o campo possui mascara
   * se tiver mascara identifica se o 
   * campo field_system tem valor 
   * se tiver usa a mascara pre-definida
   * 
   * @type {Number}
   */
  has_mask;
  /**
   * Identifica se o campo é obrigatorio
   * 
   * @type {Number}
   */
  has_required;

  /**
  * O valor aqui é usado tanto no ID 
  * como também na tag NAME do campo HTML
  * Tendo excecoes que quando o field_system
  * está preenchido ignora.
   * 
   * @type {String}
   */
  id;

  /**
   * Esse valor identifica a tag name
   * do campo caso exista valores
   * 
   * @type {String}
   */
  name;
  /**
   * Placeholder do campo
   *
   * @type {String}
   */
  placeholder;
  /**
   * Tipo do campo
   * input,select, textarea [....]
   *
   * @type {String}
   */
  type;
}